import * as React from "react";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";
import { Button } from "components/button/Button";

import Logo from "assets/svg/heritage-beds-logo.svg";

import s from "./404.scss";

export const Error = () => (
  <div className={s.error}>
    <Container>
      <div className={s.error__block}>
        <Row>
          <div className={s.error__col}>
            <Logo />
            <h1 className={s.error__heading}>
              The page you was looking for cannot be found
            </h1>
            <p className={s.error__subtext}>
              Sorry about this, let’s get you back to home
            </p>
            <Button to="/">Go back home</Button>
          </div>
        </Row>
      </div>
    </Container>
  </div>
);
